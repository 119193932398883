import { version } from './version';

const GATEWAY_BASE_URL = 'https://dev-dashboard.apis.worldpay.com/apigateway/';
const GATEWAY_BASE_URL_INVITE = 'https://test-eapi.fisglobal.com/';
const CLIENT_BASE_URL = 'https://dev-dashboard.apis.worldpay.com/';
const AWS_URL = 'https://dev-eapi.fisglobal.com/';
const authConfig = {
  'API_UAT': {
    issuer: 'https://login8-uat.fiscloudservices.com/idp/API_UAT',
    clientId: 'API-Dashboard-APIUAT-FIRM-DEV-Portal',
    dummyClientSecret: '09f52f71054b70490d0bc900fc61b6c6999c',
    idpApiKey: 'WPAY_API_KEY',
    redirectUri: 'https://dev-dashboard.apis.worldpay.com/idp/callback?fid=API_UAT',
    autoRefresh: false,
    logoutUrl: 'https://login8-uat.fiscloudservices.com/idp/API_UAT/globalLogout.html?client_id=API-Dashboard-APIUAT-FIRM-DEV-Portal',
    waitForTokenInMse: 1000,
    authorizationClaimProperty: 'UserAuthorizations',
    unauthorizedRoute: ['/'],
    listenToToken: 'id_token',
    responseType: 'code',
    encrypted: true,
    interceptUrls: ['origin', 'https://dev-dashboard.apis.worldpay.com/apigateway/', 'https://test-eapi.fisglobal.com/apigateway', 'https://cert-eapi.fisglobal.com/apigateway', 'https://cert-dashboard.events.worldpay.com/apigateway', 'https://dashboard.events.worldpay.com/apigateway']
  },
  'us_worldpay_fis_int-pbint': {
    issuer: 'https://login8-uat.fiscloudservices.com/idp/us_worldpay_fis_int',
    clientId: 'API-Dashboard-USINTERNAL-FIRM-DEV-Portal',
    dummyClientSecret: '616f817a009240438009186038d61cb3bdee',
    idpApiKey: 'BenPortalkey',
    redirectUri: 'https://dev-dashboard.apis.worldpay.com/idp/callback?fid=us_worldpay_fis_int-pbint',
    autoRefresh: false,
    logoutUrl: 'https://dev-dashboard.apis.worldpay.com/idp/us_worldpay_fis_int/globalLogout.html?client_id=API-Dashboard-USINTERNAL-FIRM-DEV-Portal',
    waitForTokenInMse: 1000,
    authorizationClaimProperty: 'UserAuthorizations',
    unauthorizedRoute: ['/'],
    listenToToken: 'id_token',
    responseType: 'code',
    encrypted: true,
    interceptUrls: ['origin', 'https://dev-dashboard.apis.worldpay.com/apigateway/', 'https://test-eapi.fisglobal.com/apigateway/', 'https://cert-eapi.fisglobal.com/apigateway/', 'https://cert-dashboard.events.worldpay.com/gateway', 'https://dashboard.events.worldpay.com/apigateway']
  }
};

export const ENV = {
  retry: true,
  MAX_TOKEN_SIZE: 128000,
  production: false,
  environment: 'Dev',
  defaultFirm: 'API_UAT',
  authConfig: authConfig,
  VERSION: version,
  SESSION_WARNING_DISPLAY_TIME: 840,
  SESSION_WARNING_EXPIRY_TIME: 60,
  USING_IDP: true,
  GATEWAY_BASE_URL: GATEWAY_BASE_URL,
  GATEWAY_BASE_URL_INVITE: GATEWAY_BASE_URL_INVITE,
  CLIENT_BASE_URL: CLIENT_BASE_URL,
  AWS_BASE_URL: AWS_URL,
  IDPBaseURL: 'https://login8-uat.fiscloudservices.com/idp',
  logout: 'https://dev-dashboard.apis.worldpay.com/idp/API_UAT/globalLogout.html?client_id=API-Dashboard-APIUAT-FIRM-DEV-Portal',
  IdpGlobalLogout: '',
  keepAliveUrl: "https://login8-uat.fiscloudservices.com/idp/",
  keepAliveSec: 120000,
  // pendo app details (to collect user behaviour)
  pendoApiKey: '',
  pendoAccountId: '',
  companyId: 'eec18885-79f9-4110-a6e8-ca906a55e23e',
};
